import React from 'react'
import { get, isEmpty, map } from 'lodash'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tippy'

// components
import ElementFailure from '../ElementFailure'
import ElementLoading from '../ElementLoading'
import ElementEmptyContent from '../ElementEmptyContent'

// actions
import * as DigitalizaciaActions from '../../actions/ObchodniPartneri/Digitalizacia'

// atoms
import Select from '../../atoms/DigitalizaciaBasicSelect'

// utils
import { history } from '../../utils/history'
import { DEFINICIA_TYP, STAV_INDIKATORA, SUHLASY_KATEGORIA } from '../../utils/enums'
import { OP_UPRAVIT, setRouteParams, ZMENA_SUHLASU, ZMLUVNE_UCTY_UPRAVIT } from '../../utils/routes'

class DigitalizaciaWidget extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}),
		overview: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		const { digitalizaciaActions } = this.props

		digitalizaciaActions.loadDigitalizacia()
	}

	commonContentContainer = (content) => {
		return <div className='box-content'>{content}</div>
	}

	getStyleByState = (state) => {
		// colors are defined in global.less
		switch (state) {
			case STAV_INDIKATORA.GREEN:
				return {
					backgroundColor: '#BAE2B8 !important',
					cursor: 'pointer'
				}
			case STAV_INDIKATORA.GRAY:
				return {
					backgroundColor: '#F4F6F9 !important',
					cursor: 'pointer'
				}
			case STAV_INDIKATORA.RED:
				return {
					backgroundColor: '#FFB6B6 !important',
					cursor: 'pointer'
				}
			case STAV_INDIKATORA.AMBER:
				return {
					backgroundColor: '#FDEB9C !important',
					cursor: 'pointer'
				}
			default:
				return {
					backgroundColor: '#FFFFFF !important',
					cursor: 'pointer'
				}
		}
	}

	getUrl = (definiciaId, opCislo, zuCislo) => {
		if (definiciaId === DEFINICIA_TYP.KONTAKTNY_TELEFON || definiciaId === DEFINICIA_TYP.KONTAKTY_EMAIL) {
			return setRouteParams(OP_UPRAVIT, opCislo)
		}
		if (definiciaId === DEFINICIA_TYP.E_FAKTURA || definiciaId === DEFINICIA_TYP.E_PLATBA || definiciaId === DEFINICIA_TYP.E_PREPLATKY) {
			return setRouteParams(ZMLUVNE_UCTY_UPRAVIT, opCislo, zuCislo)
		}
	}

	redirectToUri = (event) => {
		const { interakcia } = this.props

		const opCislo = get(interakcia, 'opCislo')
		const definiciaId = get(event, 'value.definiciaId')
		const zuCislo = get(event, 'value.zmluvnyUcet')
		history.push(this.getUrl(definiciaId, opCislo, zuCislo))
	}

	render() {
		const { t, overview, interakcia } = this.props

		const definicie = get(overview, 'data.definicie')

		if (get(overview, 'isLoading')) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (get(overview, 'isFailure')) {
			return this.commonContentContainer(<ElementFailure text={t('translation:Digitalizacia.Nepodarilo sa načítať definicie indikátorov')} />)
		}

		if (isEmpty(definicie)) {
			return this.commonContentContainer(<ElementEmptyContent text={t('translation:Digitalizacia.Žiadne indikátory na zobrazenie')} />)
		}

		const definicieFormatted = map(definicie, (definicia) => {
			const options = map(get(definicia, 'suhlasy', []), (suhlas) => ({
				label: get(suhlas, 'nazov'),
				value: { ...suhlas, definiciaId: get(definicia, 'definiciaId') }
			}))

			const styles = {
				option: (provided, state) => ({
					...provided,
					...this.getStyleByState(get(state, 'value.stav')),
					color: '#191919'
				}),

				control: (provided) => ({
					...provided,
					...this.getStyleByState(get(definicia, 'stav')),
					borderRadius: '5px',
					borderWidth: '0px',
					boxShadow: 'none !important'
				}),

				placeholder: (provided) => ({
					...provided,
					top: '50%',
					color: '#191919'
				}),

				indicatorSeparator: (provided) => ({
					...provided,
					backgroundColor: '#191919'
				}),

				dropdownIndicator: (provided) => ({
					...provided,
					color: '#191919'
				}),

				indicatorsContainer: (provided) => ({
					...provided
				})
			}

			// NOTE: adding link to placeholder based od CP-3205 request
			const showLink =
				(definicia?.stav === STAV_INDIKATORA.AMBER || definicia?.stav === STAV_INDIKATORA.RED) &&
				(definicia?.definiciaId === DEFINICIA_TYP.E_FAKTURA ||
					definicia?.definiciaId === DEFINICIA_TYP.E_PLATBA ||
					definicia?.definiciaId === DEFINICIA_TYP.E_PREPLATKY)

			const link = (
				<Tooltip title={t('translation:Digitalizacia.Zmena dôvodu nesúhlasu')} position='top-start' trigger='mouseenter' theme='light'>
					<Link
						onMouseDown={(e) => {
							e.preventDefault()
							e.stopPropagation()
						}} // Prevents closing select when clicking link
						style={{ textDecoration: 'none' }}
						to={`${setRouteParams(ZMENA_SUHLASU, get(interakcia, 'opCislo'), get(definicia, 'definiciaId'))}?suhlasKategoriaId=${
							SUHLASY_KATEGORIA.DIGITALIZACIA
						}`}
					>
						{definicia?.nazov}
					</Link>
				</Tooltip>
			)

			return (
				<div key={get(definicia, 'definiciaId')} style={{ marginBottom: '16px' }}>
					<Select
						className='digitalizacia-widget'
						key={get(definicia, 'definiciaId')}
						name={get(definicia, 'definiciaId')}
						placeholder={showLink ? link : definicia?.nazov}
						options={options}
						classNamePrefix='react-select'
						isSearchable={false}
						isDisabled={false}
						customStyles={styles}
						onChange={(event) => this.redirectToUri(event)}
					/>
				</div>
			)
		})

		return this.commonContentContainer(<form onSubmit={(e) => e.preventDefault()}>{definicieFormatted}</form>)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	overview: get(state, 'obchodnyPartner.digitalizacia')
})

const mapDispatchToProps = (dispatch) => ({
	digitalizaciaActions: bindActionCreators(DigitalizaciaActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(DigitalizaciaWidget)
