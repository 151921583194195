import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Tooltip } from 'react-tippy'
import { get } from 'lodash'

// atoms
import CheckboxField from '../../../atoms/CheckboxField'
import AddressField from '../../../atoms/NewAddressFields/AddressField'

// components
import { ConditionalWrapper } from '../../ConditionalWrapper'

// utils
import { formatAddress, checkIfAddressesAreEqual } from '../../../utils/address'
import { formatFormValue } from '../../../utils/form'

import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

const NASTAV_ADRESY_ZU_PATH = 'data.adresaKorespondencnaZmena.nastavAdresyZU'
const NASTAV_ADRESY_ZU_PATH_DETAIL = 'adresaKorespondencnaZmena.nastavAdresyZU'

class KorespondencnaAdresaField extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired,
		editMode: PropTypes.string.isRequired,
		field: PropTypes.string.isRequired,
		value: PropTypes.shape().isRequired,
		originalValue: PropTypes.shape(),
		columnsCount: PropTypes.string,
		nastavAdresyZU: PropTypes.array
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, change, editMode } = this.props

		if (editMode === EDIT_MODE.CREATE || editMode === EDIT_MODE.EDIT) {
			dispatch(change(NASTAV_ADRESY_ZU_PATH, false))
		}
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('components:GenericFields.Korešpondenčná adresa je povinná')
		}
	}

	render() {
		const { t, field, value, originalValue, columnsCount, editMode, adresyPosta, nastavAdresyZU, formValues, isEditable } = this.props

		const isAddressEqual = checkIfAddressesAreEqual(originalValue, value)
		const nastavAdresyZUValue = get(formValues, NASTAV_ADRESY_ZU_PATH) ?? get(formValues, NASTAV_ADRESY_ZU_PATH_DETAIL)

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP || editMode == EDIT_MODE.CREATE) && (
							<tr>
								<td>
									<strong>{t('components:GenericFields.Korešpondenčná adresa')}</strong>
								</td>
								{columnsCount === COLUMNS_COUNT.THREE && <td>{formatAddress(originalValue)}</td>}
								<td>
									<Field
										name={field}
										create
										edit
										component={AddressField}
										isEditable={isEditable}
										addresses={adresyPosta}
										validate={this.validate}
									/>
									<div style={{ display: 'flex', gap: '5px', alignItems: 'center', marginTop: '10px' }}>
										<Field
											name={`data.adresaKorespondencnaZmena.nastavAdresyZU`}
											component={CheckboxField}
											label={t('components:GenericFields.Nastav adresy ZU')}
											disabled={!Array.isArray(nastavAdresyZU) || nastavAdresyZU?.length === 0}
										/>
										<Tooltip
											html={
												<span>
													{t(
														'components:GenericFields.Zvolená adresa bude nastavená ako adresa pre poštovú komunikáciu a zároveň ako fakturačná adresa na všetkých zmluvných účtoch'
													)}
												</span>
											}
											position='bottom'
											trigger='mouseenter'
											theme='light'
										>
											<div className='info-icon' />
										</Tooltip>
									</div>
								</td>
							</tr>
						)}

						{(editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) && (
							<>
								<tr>
									<td>
										<strong>{t('components:GenericFields.Korešpondenčná adresa')}</strong>
									</td>
									{columnsCount === COLUMNS_COUNT.THREE && <td>{formatAddress(originalValue, false)}</td>}
									<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
										<ConditionalWrapper condition={!isAddressEqual} wrapper={(children) => <strong>{children}</strong>}>
											{formatAddress(value, false)}
										</ConditionalWrapper>
									</td>
								</tr>
								{(value?.doRukMeno || originalValue?.doRukMeno) && (
									<tr>
										<td>
											<strong>{t('components:GenericFields.Do rúk')}</strong>
										</td>
										{columnsCount === COLUMNS_COUNT.THREE && <td>{originalValue?.doRukMeno}</td>}
										<td>{formatFormValue(value?.doRukMeno, originalValue?.doRukMeno)}</td>
									</tr>
								)}
								{typeof nastavAdresyZUValue === 'boolean' && (
									<tr>
										<td>
											<strong>{t('components:GenericFields.Nastav adresy ZU')}</strong>
										</td>
										{columnsCount === COLUMNS_COUNT.THREE && <td />}
										<td>{nastavAdresyZUValue ? t('translation:Common.Áno') : t('translation:Common.Nie')}</td>
									</tr>
								)}
							</>
						)}
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	addresses: state.formAddresses.data
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(KorespondencnaAdresaField)
